import { computed, defineComponent } from 'vue';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ADMIN_STORE_KEY, AdminAction, AdminGetter } from 'store/admin';
import { IDashboard } from 'api/adminService';
import { useApiErrors } from 'composables/apiErrors';
import { RouteNames } from 'router/names';
import Dashboard from './components/Dashboard';
import List from './components/List';

const staticPages: IDashboard['pages'] = {
  serviceSettings: {
    url: 'admin-panel-service-settings',
    title: 'Настройки услуг',
  },
};

const AdminPanel = defineComponent({
  name: RouteNames.AdminPanel,
  components: {
    Dashboard,
    List,
  },
  setup() {
    const { back } = useRouter();
    const { showError } = useApiErrors();

    const store = useStore();
    store.dispatch(`${ADMIN_STORE_KEY}/${AdminAction.FetchDashboard}`).catch((e) => showError(e));

    const metrics = computed(() => store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.Metrics}`]);
    const pages = computed(() => store.getters[`${ADMIN_STORE_KEY}/${AdminGetter.Pages}`]);

    function goBack() {
      back();
    }

    return {
      goBack,

      staticPages,
      pages,
      metrics,
    };
  },
  data: () => ({
    Size,
    IconName,
    IconType,
  }),
});

export default AdminPanel;
