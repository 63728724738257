<template>
  <div class="dashboard">
    <div
      v-if="metrics.users"
      class="dashboard_item dashboard-item"
    >
      <div class="dashboard-item_title">{{ metrics.users }}</div>
      <div class="dashboard-item_subtitle">{{ $t('pages.adminPanel.users') }}</div>
    </div>
    <div
      v-if="metrics.transactions"
      class="dashboard_item dashboard-item"
    >
      <div class="dashboard-item_title">{{ formatCurrency(metrics.transactions) }}</div>
      <div class="dashboard-item_subtitle">{{ $t('pages.adminPanel.transactions') }}</div>
    </div>
  </div>
</template>

<script lang="ts" src="./Dashboard.ts" />
<style lang="scss" src="./dashboard.scss" />
