import { RouteNames } from 'router/names';
import { computed, defineComponent } from 'vue';
import { UsersGetter, useUsersGetter } from 'store/users';
import { ThemeColor } from 'api/users/models/user';
import { TxtType } from 'components/Txt';
import { useRouter } from 'vue-router';
import Blue from './assets/blue.raw.svg';
import Red from './assets/red.raw.svg';
import Lavender from './assets/lavender.raw.svg';

const NotFound = defineComponent({
  name: RouteNames.NotFound,
  setup() {
    const router = useRouter();
    const activeThemeColor = computed(() =>
      useUsersGetter<ThemeColor>(UsersGetter.ThemeColor) || ThemeColor.Lavender);

    const icon = computed(() => {
      if (activeThemeColor.value === 'red') return Red;
      if (activeThemeColor.value === 'blue') return Blue;
      return Lavender;
    });

    function back() {
      router.replace('/');
    }

    return {
      activeThemeColor,
      icon,
      back,

      TxtType,
    };
  },
});

export default NotFound;
