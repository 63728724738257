import { render } from "./Wa.vue?vue&type=template&id=285e71f6&scoped=true"
import script from "./Wa.vue?vue&type=script&lang=js"
export * from "./Wa.vue?vue&type=script&lang=js"

import "./Wa.vue?vue&type=style&index=0&id=285e71f6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-285e71f6"
/* hot reload */
if (module.hot) {
  script.__hmrId = "285e71f6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('285e71f6', script)) {
    api.reload('285e71f6', script)
  }
  
  module.hot.accept("./Wa.vue?vue&type=template&id=285e71f6&scoped=true", () => {
    api.rerender('285e71f6', render)
  })

}

script.__file = "src/views/Wa/Wa.vue"

export default script