<template>
  <main class="admin-panel">
    <div class="admin-panel-header">
      <h4>{{ $t('pages.adminPanel.title') }}</h4>
      <icon-btn
        :size="Size.M"
        @click="goBack"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>
    <div class="admin-panel_content">
      <dashboard :metrics="metrics" />
      <list
        class="admin-panel_metrics"
        :pages="pages"
        :static-pages="staticPages"
      />
    </div>
  </main>
</template>

<script lang="ts" src="./AdminPanel.ts" />
<style lang="scss" src="./adminPanel.scss" />
