import { render } from "./Dashboard.vue?vue&type=template&id=243c87b0"
import script from "./Dashboard.ts?vue&type=script&lang=ts"
export * from "./Dashboard.ts?vue&type=script&lang=ts"

import "./dashboard.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "243c87b0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('243c87b0', script)) {
    api.reload('243c87b0', script)
  }
  
  module.hot.accept("./Dashboard.vue?vue&type=template&id=243c87b0", () => {
    api.rerender('243c87b0', render)
  })

}

script.__file = "src/views/AdminPanel/components/Dashboard/Dashboard.vue"

export default script