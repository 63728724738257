import { render } from "./NotFound.vue?vue&type=template&id=6fcf2836"
import script from "./NotFound.ts?vue&type=script&lang=ts"
export * from "./NotFound.ts?vue&type=script&lang=ts"

import "./notFound.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6fcf2836"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6fcf2836', script)) {
    api.reload('6fcf2836', script)
  }
  
  module.hot.accept("./NotFound.vue?vue&type=template&id=6fcf2836", () => {
    api.rerender('6fcf2836', render)
  })

}

script.__file = "src/views/NotFound/NotFound.vue"

export default script