import { render } from "./List.vue?vue&type=template&id=b71a6374"
import script from "./List.ts?vue&type=script&lang=ts"
export * from "./List.ts?vue&type=script&lang=ts"

import "./list.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b71a6374"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b71a6374', script)) {
    api.reload('b71a6374', script)
  }
  
  module.hot.accept("./List.vue?vue&type=template&id=b71a6374", () => {
    api.rerender('b71a6374', render)
  })

}

script.__file = "src/views/AdminPanel/components/List/List.vue"

export default script