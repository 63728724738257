<template>
  <div>
    <router-link
      v-for="[key, value] in staticPagesArray"
      :key="key"
      :to="{ name: value.url }"
      class="admin-panel-list-item"
    >
      <div class="admin-panel-list-item_arrow">
        <icon
          color="white"
          :name="IconName.ArrowRight"
          :type="IconType.Contour"
          :size="Size.S"
        />
      </div>
      <div class="admin-panel-list-item_title">
        {{ value.title }}
      </div>
    </router-link>
    <a
      v-for="[key, value] in pagesArray"
      :key="key"
      :href="value.url"
      target="_blank"
      rel="noopener noreferrer"
      class="admin-panel-list-item"
    >
      <div class="admin-panel-list-item_arrow">
        <icon
          color="white"
          :name="IconName.ArrowRight"
          :type="IconType.Contour"
          :size="Size.S"
        />
      </div>
      <div class="admin-panel-list-item_title">
        {{ value.title }}
      </div>
    </a>
  </div>
</template>

<script lang="ts" src="./List.ts" />
<style lang="scss" src="./list.scss" />
