<template>
  <div class="not-found-page">
    <div v-html="icon" />
    <txt
      class="not-found-page__title"
      :type="TxtType.Heading1"
    >
      404
    </txt>
    <p class="not-found-page__description">{{ $t('pages.notFound.description') }}</p>

    <btn
      w="100%"
      @click="back"
    >
      {{ $t('pages.notFound.back') }}
    </btn>
  </div>
</template>

<script lang="ts" src="./NotFound.ts" />
<style lang="scss" src="./notFound.scss" />
