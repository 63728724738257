<template>
  <div class="pt-3 text-center">
    <h1 class="wa-text font-bold">Open WhatsApp</h1>

    <a
      :href="`https://web.whatsapp.com/send/?phone=${phone}`"
      class="wa-btn"
    >
      OPEN WHATSAPP WEB
    </a>
  </div>
</template>

<script>
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { RouteNames } from '@/router/names';

export default {
  name: RouteNames.Wa,
  setup() {
    const route = useRoute();

    const router = useRouter();

    const { phone } = route.query;

    const toWhatsApp = () => {
      window.location.href = `whatsapp://send?phone=${phone}`;
    };

    toWhatsApp();

    return {
      phone,
    };
  },
};
</script>

<style lang="scss" scoped>
.wa-text {
  font-size: 30px;
  margin-bottom: 15px;
}

.wa-btn {
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  margin: 0;
  vertical-align: baseline;
  text-transform: uppercase;
  background-color: #01e675;
  color: #fff!important;
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  height: 19px;
  letter-spacing: .02em;
  line-height: 19px;
  padding: 15px 24px;
  white-space: nowrap;
}
</style>
