import { defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { IDashboard } from 'api/adminService';
import { useCurrency } from 'composables/currency';

const Dashboard = defineComponent({
  props: {
    metrics: {
      type: Object as PropType<IDashboard['metrics']>,
      required: true,
    },
  },
  data() {
    const { formatCurrency } = useCurrency();

    return {
      formatCurrency,
    };
  },
});

export default Dashboard;
