import { computed, defineComponent } from 'vue';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import { PropType } from '@vue/runtime-core';
import { IDashboard } from 'api/adminService';

const List = defineComponent({
  props: {
    pages: {
      type: Object as PropType<IDashboard['pages']>,
      required: true,
    },
    staticPages: {
      type: Object as PropType<IDashboard['pages']>,
      default: () => ({}),
    },
  },
  setup(props) {
    const pagesArray = computed(() => Object.entries(props.pages));
    const staticPagesArray = computed(() => Object.entries(props.staticPages));

    return {
      pagesArray,
      staticPagesArray,
    };
  },
  data() {
    return {
      Size,
      IconName,
      IconType,
    };
  },
});

export default List;
