import { render } from "./AdminPanel.vue?vue&type=template&id=96c6e5b6"
import script from "./AdminPanel.ts?vue&type=script&lang=ts"
export * from "./AdminPanel.ts?vue&type=script&lang=ts"

import "./adminPanel.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "96c6e5b6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('96c6e5b6', script)) {
    api.reload('96c6e5b6', script)
  }
  
  module.hot.accept("./AdminPanel.vue?vue&type=template&id=96c6e5b6", () => {
    api.rerender('96c6e5b6', render)
  })

}

script.__file = "src/views/AdminPanel/AdminPanel.vue"

export default script